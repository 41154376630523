.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.fa-1x {
  font-size: 1.5rem;
}
.navbar-toggler.toggler-example {
  cursor: pointer;
}
.dark-blue-text {
  color: #0a38f5;
}
.dark-pink-text {
  color: #ac003a;
}
.dark-amber-text {
  color: #ff6f00;
}
.dark-teal-text {
  color: #004d40;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) and (max-height: 668px) {
  .bg_white {
    background-color: #fff;
    text-align: center;
    max-width: 1280px;

    min-height: 100%;

    margin: 0 auto;

    position: static;
  }

  .App {
    text-align: center;
    max-width: 1280px;
    background-color: var(--ice);


    margin: 0 auto;

    position: relative;
  }
  .centered-element {
    margin-left: 16px;
    margin-right: 16px;
    height: 670px;
    /* padding: 5px;*/
  }

  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
@media only screen and (max-width: 600px) and (min-height: 668px) {
  .bg_white {
    text-align: center;
    /*max-width:1280px;*/
    width: auto;
    background-color: #fff;
    min-height: 100%;

    margin: 0 auto;

    position: static;
  }

  .App {
    text-align: center;
    max-width: 1280px;
    background-color: var(--ice);


    margin: 0 auto;

    position: relative;
  }
  .centered-element {
    margin-left: 16px;
    margin-right: 16px;
    height: 840px;
    padding: 5px;
  }

  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .bg_white {
    text-align: center;
    max-width: 1280px;
    background-color: #fff;
    height: 800px;

    margin: 0 auto;

    position: static;
  }

  .App {
    text-align: center;
    max-width: 1280px;

    background-color: var(--ice);
    margin: 0 auto;

    position: relative;
  }
  .centered-element {
    margin-left: auto;
    margin-right: auto;
    height: 800px;
    padding: 5px;
  }

  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) {
  .bg_white {
    text-align: center;
    max-width: 1280px;
    background-color: #fff;
    height: 1050px;

    margin: 0 auto;

    position: static;
  }

  .App {
    text-align: center;
    max-width: 1280px;
    background-color: var(--ice);
  

    margin: 0 auto;
  }

  .centered-element {
    margin-left: auto;
    margin-right: auto;
    height: 850px;
    padding: 15px;
    max-width: 1024px;
  }
}

@media only screen and (min-width: 768px) and (min-height: 1100px) {
  .bg_white {
    text-align: center;
    max-width: 1280px;
    background-color: #fff;
    height: 1180px;

    margin: 0 auto;

    position: static;
  }

  .App {
    text-align: center;
    max-width: 1280px;
    background-color: var(--ice);


    margin: 0 auto;
  }

  .centered-element {
    margin-left: auto;
    margin-right: auto;
    height: 95%;
    padding: 15px;
    max-width: 1024px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .bg_white {
    text-align: center;
    max-width: 1280px;
    background-color: #fff;
    height: 700px;

    margin: 0 auto;
    position: static;
  }

  .App {
    text-align: center;
    max-width: 1024px;


    background-color: var(--ice);
    margin: 0 auto;

    position: relative;
  }

  .centered-element {
    margin-left: auto;
    margin-right: auto;
    height: 650px;
    padding: 15px;
  }

  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1600px) {
  .bg_white {
    text-align: center;
    max-width: 1620px;
    background-color: #fff;
    height: 1800px;

    margin: 0 auto;

    position: static;
  }

  .App {
    text-align: center;
    max-width: 1620px;
    background-color: var(--ice);

    margin: 0 auto;

    position: relative;
  }

  .centered-element {
    margin-left: auto;
    margin-right: auto;
    height: 1850px;
    padding: 15px;
  }

  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media only screen and (min-width: 1900px) {
  .App {
    text-align: center;
    max-width: 2160px;
    background-color: var(--ice);


    margin: 0 auto;

    position: static;
  }

  .centered-element {
    margin-left: auto;
    margin-right: auto;
    height: 850px;
    padding: 15px;
  }

  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
