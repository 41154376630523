

.progressimg {
   
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
   
      
}
