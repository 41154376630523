.radiusbutton {
    border-radius:6px;
    width: 100%;
   margin-top: 1rem;
}
  
.selectgroup {
    margin-bottom: 1rem;
}

.selecttitle {
    font-size: 12px;
    font-weight: bold;
}
.radiogroup {
    margin-top: 4rem;
}
.buttongroup {
    margin-top: 1rem;
}

.identification {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 1rem;
}
 .textarealabel {
 
    margin-bottom: 1rem;
 }

 .imgcolorsmall {
    color: #1572c5;
    height: 30px;
    width: 30px;
   
    vertical-align: middle;
    margin:auto;
    display:flex;
    border:solid 2px #1572c5;
    border-radius: 45px;
    float: left;
   
  }
 
  @media only screen and (max-width: 600px) {
    .footer {
    background-image: linear-gradient(#fff, var(--ice));
    border-radius: 0px;
  align-items: center;
      position: fixed;
      bottom: 0;
    
     max-width:1024px;
  }
    }
  

  @media only screen and (min-width: 600px) {
    .footer {
    background-image: linear-gradient(#fff, var(--ice));
    border-radius: 0px;
  align-items: center;
      position:absolute;
      bottom: 0;
    
     max-width:1024px;
  }
    }
  

  @media only screen and (min-width: 992px) {
  .footer {
  background-image: linear-gradient(#fff, var(--ice));
  border-radius: 0px;
align-items: center;
    position:absolute;
    bottom: 0;
    max-width:1024px;
}
  }
  @media only screen and (min-width: 1600px) {
    .footer {
    background-image: linear-gradient(#fff, var(--ice));
    border-radius: 0px;
  align-items: center;
      position: absolute;
      bottom: 0;
      max-width:1620px;
  }
    }
    @media only screen and (min-width: 1900px) {
      .footer {
      background-image: linear-gradient(#fff, var(--ice));
      border-radius: 0px;
    align-items: center;
        position: absolute;
        bottom: 0;
        max-width:2160px;
    }
      }

  .inputinfo {
    width: 300px;
  }