
.cardContent-list {

  margin-left: auto;
  margin-right: auto;
  height: 850px;
  padding: 15px;
  max-width: 1024px;
}
  
  .cardContent {
    flex-direction: column;
    justify-content: space-between;
    border-radius: 11px;
    background-color:  #fff;
    box-shadow: 0 0 3px 0px rgba(43, 28, 71, 0.25);
    backdrop-filter: blur(15px);
  }


  .date-range {

    margin-bottom:2px;

  }

 

  input {

    margin-bottom: 10px;

    padding: 5px;

    width: 200px;

  }