.btnsection {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.btnhomesection {
  width: 100%;
  height: 350px;
  display: flex;
  align-items: center;
  vertical-align: middle;
}
.span {
  float: left;
  text-align: start;
  padding-bottom: 0.5rem;
}

.filenamesection {
  height: 10px;

  align-items: center;
  text-align: center;
  padding-top: 1rem;
}
.detailcell {
  padding-right: 5px;
  width: 100px;
  text-align: start;
}
.containtdetail {
  border: 1px solid #1572c5;
  border-radius: calc(3rem / 2);
  padding-top: 15px;
  padding-left: 15px;
  margin-top: 1%;
  background-color: #fff;
}
.errortext {
  color: red;
  text-align: start;
  font-weight: var(--fw-medium);
}
.totaltext {
  color: #1572c5;
  text-align: start;
  font-weight: var(--fw-medium);
}
.successtext {
  color: green;
  text-align: start;
  font-weight: var(--fw-medium);
}

.untreatedtext {
  color: rgb(236, 183, 10);
  text-align: start;
  font-weight: var(--fw-medium);
}
.progressimg {
  padding-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
