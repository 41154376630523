.radiusbutton {
  border-radius: 6px;
  width: 100%;
  margin-top: 1rem;
}

.selectgroup {
  width: 300px;
}

.selecttitle {
  font-size: 12px;
  font-weight: bold;
}
.textarealabel {
  margin-bottom: 1rem;
}
.buttongroup {
  margin-top: 1rem;
}

.identification {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 1rem;
}

.example-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.example-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.example-exit {
  opacity: 1;
  transform: translateX(0);
}
.example-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 300ms, transform 300ms;
}
.imgcolorsmall {
  color: #1572c5;
  height: 40px;
  width: 40px;

  vertical-align: middle;
  margin: auto;
  display: flex;
  border: solid 2px #1572c5;
  border-radius: 45px;
  float: left;
}
.arrowsection {
  padding-top: 10%;
  align-items: center;
  vertical-align: middle;
}
.inputinfo {
  width: 300px;
}
@media only screen and (max-width: 600px) {
  .footer {
    background-image: linear-gradient(#fff, var(--ice));
    border-radius: 0px;
    align-items: center;
    position: fixed;
    bottom: 0;
    margin-top: auto;
    max-width: 1024px;
  }
}

@media only screen and (min-width: 600px) {
  .footer {
    background-image: linear-gradient(#fff, var(--ice));
    border-radius: 0px;
    align-items: center;
    position: fixed;
    bottom: 0;
    margin-top: auto;
    max-width: 1024px;
  }
}

@media only screen and (min-width: 992px) {
  .footer {
    background-image: linear-gradient(#fff, var(--ice));
    border-radius: 0px;
    align-items: center;
    position: fixed;
    bottom: 0;
    max-width: 1024px;
    margin-top: auto;
  }
}

@media only screen and (min-width: 1600px) {
  .footer {
    background-image: linear-gradient(#fff, var(--ice));
    border-radius: 0px;
    align-items: center;
    position: fixed;
    bottom: 0;
    margin-top: auto;
    max-width: 1620px;
  }
}
@media only screen and (min-width: 1900px) {
  .footer {
    background-image: linear-gradient(#fff, var(--ice));
    border-radius: 0px;
    align-items: center;
    position: fixed;
    bottom: 0;
    margin-top: auto;
    max-width: 2160px;
  }
}
