@import "@nbc-design/tokens/dist/assets/bnc-ui-fonts.css";
@import "~@nbc-design/tokens/dist/styles/index.css";

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.formlabel {
  /* font-family: var(--ff-system);*/
  font-size: 18px;
  text-align: start;
}
.formselect {
  /* font-family: var(--ff-system);*/
  font-size: var(--fs-400);
  font-weight: var(--fw-medium);
  text-align: start;
  margin-bottom: 10px;
}
.formselectsingle {
  /*font-family: var(--ff-system);*/
  font-size: var(--fs-400);
  font-weight: var(--fw-medium);
  text-align: start;
  margin-bottom: 10px;
}
.formradio {
  /* font-family: var(--ff-system);*/
  font-size: 16px;
}
.admintitle {
  font-size: var(--fs-400);
  font-weight: var(--fw-medium);
}

ul {
  list-style: none;

  padding: 0;

  margin: 0;
}

a {
  text-decoration: none;
}

a:active,
a:focus {
  outline: none;
}

.badgesection {
  height: 32px;
}

.bg_screen {
  background-color: var(--ice);
}

.bg_body {
  background-color: var(--ice);
}
.roomname {
  padding-top: 5px;
  color: black;
  font-size: var(--fs-600);
  font-weight: var(--fw-semi-bold);
  font-family: var(--ff-gilroy);
}
.roomtitle {
  color: #628eb6;
  font-size: 18px;
  font-weight: 800;
  font-family: var(--ff-gilroy);
}

.bg_text {
  text-align: start;

  /* padding-left: 5%;*/
}
.btntext {
  font-family: var(--ff-gilroy);
  padding: 0.5rem 0 0.5rem 0;
}

.is-padding.dsc-btn.is-large {
  padding: 0.5rem 0 0.5rem 0;
}

.img03 {
  float: center;
  margin-left: 5px;
  margin-right: 5px;
  /* margin-right: 25px;
  padding-top: 50px;*/
  padding-right: 5px;

  max-width: 60px;
  height: 50px;
}

.mandatory {
  color: red;
  padding-left: 4px;
  font-size: 23px;
}

.spc-card-button.dsc-cardButton {
  margin-bottom: var(--s-xsmall);
  padding: var(--s-normal);
}
.spc-card-button.dsc-cardButton .dsc-radio__label {
  min-height: 0;
  padding: var(--s-normal);
  margin: calc((var(--bs-small) * -1) + (var(--s-normal) * -1));
}

.dsc-btn-cust:not(:disabled):not([aria-disabled="false"]):not(.is-disabled):hover:not(:disabled).dsc-btn.dsc-btn.is-fluid.dsc-btn.is-primary {
  background: #1572c5;
  border-color: #1572c5;
}

.bg_gradian {
  background-image: linear-gradient(#fff, var(--ice));
}

.div {
  animation-duration: var(--ad-long);
  animation-name: var(--ease-in-out-cubic);
}

.fadeff-enter {
  opacity: 0;
  transform: translateX(100%);
}
.fadeff-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: var(--ease-in-out-cubic) transform var(--ad-long);
}
.fadeff-exit {
  opacity: 0;
  transform: translateX(-100);
}
.fadeff-exit-active {
  opacity: 0;
  transform: translateX(0);
  transition: var(--ease-in-out-cubic) transform var(--ad-long);
}

.faderev-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.faderev-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: var(--ease-in-out-cubic) transform var(--ad-long);
}
.faderev-exit {
  opacity: 1;
  transform: translateX(0);
}
.faderev-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: var(--ease-in-out-cubic) transform var(--ad-long);
}

.slide-left-enter {
  opacity: 0;
  transform: translateX(100%);
}
.slide-left-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 2500ms;
}
.slide-left-exit {
  opacity: 1;
  transform: translateX(0%);
}
.slide-left-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 2500ms;
}

.slide-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.slide-right-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 2500ms;
}
.slide-right-exit {
  opacity: 1;
  transform: translateX(0%);
}
.slide-right-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: all 2500ms;
}
.logo_qrcode{
  margin: 0 auto;
  text-align: center;
}
.test {
  animation-duration: 3s;
  animation-name: glissement;
  animation-iteration-count: infinite;
}

.dsc-btn.is-primary:not(:disabled):not([aria-disabled="true"]):not(.is-disabled):hover:not(:disabled) {
  background: #1572c5;
  border-color: #1572c5;
}
.form-control-description{
  width: 100%;
  max-width: 500px;
}
@keyframes glissement {
  from {
    margin-right: 100%;
    width: 300%;
  }

  to {
    margin-right: 0%;
    width: 100%;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) and (max-height: 668px) {
 

  .contentbutton {
    width: auto;
  }
  .btnsection {
    height: 400px;
  }
  .middlesection {
    height: 50%;
  }
  .picsection {
    height: 95%;
  }
  .middlesectionMsg {
    height: 55%;
  }
}

@media only screen and (max-width: 600px) and (min-height: 668px) {
  .form-control-description{
    width: 330px;
  }
  .contentbutton {
    width: auto;
  }
  .picsection {
    height: 95%;
  }
  .btnsection {
    height: 535px;
  }
  .middlesection {
    height: 50%;
  }

  .middlesectionMsg {
    height: 55%;
  }
}
@media only screen and (max-width: 600px) and (min-height: 780px) {
  .contentbutton {
    width: auto;
  }

  .picsection {
    height: 95%;
  }
  .btnsection {
    height: 650px;
  }
  .middlesection {
    height: 50%;
  }

  .middlesectionMsg {
    height: 55%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .contentbutton {
    width: auto;
  }

  .picsection {
    height: 625px;
  }
  .btnsection {
    height: 700px;
  }
  .middlesection {
    height: 50%;
  }

  .middlesectionMsg {
    height: 55%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 767px) {
  .appcontent {
    width: 900px;
    height: 100%;
    text-align: center;
  }
  .contentbutton {
    width: auto;
  }
  .picsection {
    height: 625px;
  }
  .btnsection {
    height: 95%;
  }

  .middlesection {
    height: 55%;
  }

  .middlesectionMsg {
    height: 70%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

  .appcontent {
    width: 1024px;
    height: 100%;
    text-align: center;
  }
  .contentbutton {
    width: auto;
  }
  .picsection {
    height: 295px;
  }
  .btnsection {
    height: 400px;
  }

  .middlesection {
    height: 50%;
  }

  .middlesectionMsg {
    height: 50%;
  }
}

@media only screen and (min-width: 1600px) {
  .appcontent {
    width: 1024px;
    height: 100%;
    text-align: center;
  }
  .contentbutton {
    width: auto;
  }

  .picsection {
    height: 295px;
  }
  .btnsection {
    height: 300px;
  }

  .middlesection {
    height: 55%;
  }

  .middlesectionMsg {
    height: 50%;
  }
}

@media only screen and (min-width: 1900px) {
  .appcontent {
    width: 1024px;
    height: 100%;
    text-align: center;
  }
  .contentbutton {
    width: auto;
  }
  .picsection {
    height: 500px;
  }
  .btnsection {
    height: 300px;
  }

  .middlesection {
    height: 50%;
  }

  .middlesectionMsg {
    height: 50%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
/*@media only screen and (min-width: 1200px) {...}*/
