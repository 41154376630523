@import "~@nbc-design/tokens/dist/assets/bnc-ui-fonts.css";

.header {
  overflow: hidden;
  background-color: #fff;
  padding: 10px 10px;
  box-shadow: 0px 1px 4px -1px;
  margin-bottom: 4px;
  height: 48px;
  position: sticky;
  z-index: 2;
  top: 0%;
}
.headertitle {
  color: #000;
  font-family: "Gilroy";
  text-align: center;
  font-size: 18px;
  vertical-align: middle;
  font-weight: 600;
  height: 22px;
  letter-spacing: 0px;
  line-height: 22px;
  width: 45px;
  text-decoration: none;
}

.imglogo {
  padding-right: 0.5rem;
  max-width: 45px;
  min-width: 25px;
  height: auto;
}
