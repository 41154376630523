.navbar {
  flex-wrap: nowrap;

  align-items: left;

  justify-content: space-between;

  width: 10%;

  min-height: 75px;

  padding: 1rem;

  background: #444444;

  color: #fff;
  position: left;
}

.navbar__links {
  display: flex;
}

.navbar__link {
  padding: 0 0.3rem;

  color: inherit;
}

.navbar__links {
  flex-direction: column;

  justify-content: center;

  align-items: center;

  position: fixed;

  right: -100vh;

  bottom: 0;

  height: 100vh;

  padding: 2rem;

  visibility: hidden;

  background: #444444;
}

.show-nav .navbar__links {
  left: 0;
  width: 30vw;

  visibility: visible;
}

/*bonus*/

.navbar__item::after {
  content: "";

  display: block;

  margin: 0 auto;

  width: 3vw;

  height: 1px;

  background: #fff;
}

.navbar__link {
  display: block;

  padding: 1.5rem;

  font-size: 1.5vw;

  border: none;
}

.navbar__burger {
  display: block;

  position: left;

  top: 1rem;

  right: 1rem;

  border: none;
  width: 40px;

  height: 40px;

  background: transparent;

  color: inherit;
}

.navbar__burger:hover {
  cursor: pointer;
}
.burger__bar,
.burger__bar::before,
.burger__bar::after {
  display: block;

  width: 40px;

  height: 3px;

  position: relative;

  border-radius: 3px;

  background: #fff;

  transition: all 0.5s ease-in-out;
}

.burger__bar::before,
.burger__bar::after {
  content: "";

  position: absolute;

  left: 0;
}

.burger__bar::before {
  transform: translateY(-12px);
}

.burger__bar::after {
  transform: translateY(12px);
}

.show-nav .burger__bar {
  background: transparent;
}

.show-nav .burger__bar::before {
  transform: rotate(45deg);
}

.show-nav .burger__bar::after {
  transform: rotate(-45deg);
}
