.navbutton {
    width: 100%;
    height: auto;
    align-items: center;
    vertical-align: middle;
}

.qrlogo {
    max-width: 150px;
    margin: 0 auto;
}

.qrtitle {
    margin-top: 50px;
}

