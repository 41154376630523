.img03 {
  float: center;
  margin-left: 5px;
  margin-right: 5px;
  /* margin-right: 25px;
      padding-top: 50px;*/
  padding-right: 5px;
  align-items: center;
  vertical-align: top;
  max-width: 60px;
}

.table03 {
  width: 89%;
  text-align: center;
}
.p03 {
  font-weight: bold;
  font-size: 22px;
  padding: 1rem 0rem 2rem 2rem;
  text-align: left;
}
.span03 {
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  padding-right: 3rem;
  color: #000;
}

.divcontent03 {
  background-color: #fff;
  margin: 2%;
  border: solid 1px #d5d5d5;
  border-radius: 8px;
}
.divitems {
  border-bottom: solid 2px #d5d5d5;
  padding-top: 2%;

  width: 100%;
}
.divitemsnoborder {
  border-bottom: solid 2px #d5d5d5;
  padding-top: 2%;

  width: 100%;
}
.itemstitle {
  font-size: 18px;
  color: #1572c5;
  font-weight: 500;
  text-align: start;
}
.imgcolor {
  color: #1572c5;
}
.itemslink {
  font-size: 14px;
  color: #000;
  font-weight: 500;
  text-align: start;
}

.separator {
  border-bottom: 3px solid rgb(128, 127, 127);
  width: 400px;
  display: inline-block;
  margin-bottom: 2%;
}
.navbutton {
  width: 100%;
  height: auto;
  /*padding-bottom: 24px;*/
  align-items: center;
  vertical-align: middle;
}

.imgcolorsmall {
  color: #1572c5;
  height: 15px;
  width: 15px;
  text-align: center;
  margin: auto;
  display: flex;
}

.linktext {
  color: #1572c5;
  font-size: var(--fs-300);
  vertical-align: top;
  padding-left: 5px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .itemsection {
    padding-bottom: 55px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .itemsection {
    padding-bottom: 55px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .itemsection {
    padding-top: 50px;
    padding-bottom: 381px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .itemsection {
    padding-bottom: 55px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .itemsection {
    padding-bottom: 55px;
  }
}

@media only screen and (min-width: 1600px) {
  .itemsection {
    padding-bottom: 55px;
  }
}

@media only screen and (min-width: 1900px) {
  .itemsection {
    padding-top: 50px;
    padding-bottom: 255px;
  }
}
