.badgecontext {
  background-color: #386c93;
  display: inline-block;
  min-width: 50px;
  /*height:50px;*/
  border: solid 1px #ccc;
  color: #fff;
  vertical-align: top;
  margin: 10px;
  text-align: center;
  line-height: 50px;

  border-radius: 4px;
}

.statecontainer {
  padding-bottom: 5px;
}
