.navbutton {
  width: 100%;
  height: auto;
  /*padding-bottom: 24px;*/
  align-items: center;
  vertical-align: middle;
}

.imgcolorsmall {
  color: #1572c5;
  height: 15px;
  width: 15px;
  text-align: center;
  margin: auto;
  display: flex;
}

.arrowsection {
  align-items: center;
  vertical-align: middle;
  color: #1572c5;
  cursor: pointer;
}

.linktext {
  color: #1572c5;
  font-size: var(--fs-300);
  vertical-align: top;
  padding-left: 5px;
}

.categorytext {
  color: #1572c5;
  font-size: 16px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .picplaceolder {
    /*max-width: 125px;*/
    max-width: 100%;
    height: auto;
    align-items: center;
    vertical-align: middle;
    /*margin: 10% 0 35% 0;*/
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .picplaceolder {
    max-width: 100%;
    height: auto;
    align-items: center;
    vertical-align: middle;
    /*margin: 10% 16x 50% 16px;*/
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .picplaceolder {
    max-width: 70%;
    height: auto;
    align-items: center;
    vertical-align: middle;
    /*margin: 10% 0 25% 0;*/
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .picplaceolder {
    max-width: 30%;
    height: auto;
    align-items: center;
    vertical-align: middle;
    margin: 1% 0 3% 0;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .picplaceolder {
    max-width: 30%;
    height: auto;
    align-items: center;
    vertical-align: middle;
    /*margin: 10% 16x 50% 16px;*/
  }
}

@media only screen and (min-width: 1600px) {
  .picplaceolder {
    max-width: 60%;
    height: auto;
    align-items: center;
    vertical-align: middle;
    /*margin: 10% 16x 50% 16px;*/
  }
}

@media only screen and (min-width: 1900px) {
  .picplaceolder {
    max-width: 40%;
    height: auto;
    align-items: center;
    vertical-align: middle;
    /*margin: 10% 16x 50% 16px;*/
  }
}
