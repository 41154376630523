.displaygroup {
    border-bottom:solid 2px #d5d5d5;
  
}
.titlegroup {
    color: var(--grey700);
    font-size: var(--fs-300);
}

.itemgroup {
    color: var(--black);
    font-size: var(--fs-400);
    padding-bottom: 0.5rem;
}

.msgoption {
    font-size: var(--fs-500);
    font-weight: var(--fw-medium) ;
    text-align: start;
}


.navbutton {
    width: 80%;
    height: auto;
    padding: 0.5rem;
    align-items: center;
    vertical-align: middle;
  
  }

  .imgcolorsmall {
    color: #1572c5;
    height: 15px;
    width: 15px;
    text-align:center;
    margin:auto;
    display:flex;
  }
  
  .linktext {
    color: #1572c5;
    font-size: var(--fs-300);
    vertical-align: top;
    padding-left: 5px;
  }