.ulbread {
  list-style-type: none;

  overflow: hidden;
}

.libread {
  float: left;
  /*font-family: var(--SFProText-Regular);*/
  /*font-family: var(--ff-gilroy);*/
  font-size: var(--fs-300);
  padding-bottom: 0px;
}

.libreadhome {
  float: left;
}
.libread .abread {
  display: inline;
  color: #50545d;
  text-align: center;

  text-decoration: none;
}
.libreadhome .abreadhome {
  display: inline;
  text-align: center;

  text-decoration-style: solid;
}

/* Change the link color to #111 (black) on hover */
.libread .abread:hover {
  color: #50545d;
}

.pbread {
  padding: 0px 10px 0px 10px;
  color: #50545d;
}
